
import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'AppBreadcrumbs',
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  setup(props) {
    const { i18n } = useContext();
    const links = computed(() => {
      return [
        {
          title: i18n.t('main'),
          link: '/',
        },
        ...props.items,
      ];
    });

    const isLastItem = (index) => index === links.value.length - 1;

    return { links, isLastItem };
  },
});
