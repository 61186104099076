
import {
  computed,
  defineComponent,
  useFetch,
  useRoute,
  ref,
  useMeta,
} from '@nuxtjs/composition-api';
import usePage from '~/composables/usePage';
import useAlternateLinks from '~/composables/useAlternateLinks';

const mainTitleSections = [
  'hero-section',
  'header-section-background',
  'header-section-image',
  'header-section-simple',
  'header-section-with-banner',
];

export default defineComponent({
  setup() {
    const route = useRoute();
    const slug = computed(() => route.value.params.slug);
    const { alternateLinks } = useAlternateLinks();

    const { page, fetchPage, isLoaded, isLoading } = usePage(slug.value);
    const pageBlocks = ref(null);
    const isPageHasMainTitleSection = computed(() =>
      page?.value?.blocks.some((block) =>
        mainTitleSections.includes(block.content.type)
      )
    );

    useFetch(async () => {
      await fetchPage();

      pageBlocks.value = page.value.blocks;
    });
    useMeta(() => ({
      link: alternateLinks.value,
    }));

    return {
      page,
      pageBlocks,
      isLoaded,
      isLoading,
      isPageHasMainTitleSection,
    };
  },
  head: {},
});
